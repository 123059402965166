import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import {
  PagingState,
  CustomPaging,
  SearchState,
  SortingState,
  IntegratedSorting,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSelection,
  FilteringState,
  SelectionState,
  RowDetailState
} from "@devexpress/dx-react-grid";
import {
  Grid as GridTable,
  Table,
  TableHeaderRow,
  TableFilterRow,
  PagingPanel,
  TableColumnResizing,
  TableSelection,
  TableRowDetail,
} from "@devexpress/dx-react-grid-material-ui";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import { withStyles } from "@material-ui/core/styles";


const styles = {
  customHeaderCell: {
    '& div': {
      whiteSpace: "normal",
      wordWrap: "break-word",
    },
    '& svg' : {
      height: "8",
      width: "8"
    }
  }
};

const stylescenter = {
  customHeaderCell: {
    '& div': {
      whiteSpace: "normal",
      wordWrap: "break-word",
      textAlign: "center"
    },
    '& svg' : {
      height: "8",
      width: "8"
    }
  }
};

const CustomTableHeaderCellBase = ({ classes, ...restProps }) => {
  restProps.value = restProps.column.title || restProps.column.name;
  return <TableHeaderRow.Cell className={classes.customHeaderCell} {...restProps}/>
}
export const CustomTableHeaderCell = withStyles(styles)(CustomTableHeaderCellBase);


const CustomTableHeaderCellBaseCenter = ({ classes, ...restProps }) => {
  restProps.value = restProps.column.title || restProps.column.name;
  return <TableHeaderRow.Cell className={classes.customHeaderCell} {...restProps} style={{paddingLeft:4, paddingRight:0}}/>
}
export const CustomTableHeaderCellCenter = withStyles(stylescenter)(CustomTableHeaderCellBaseCenter);


const CustomTableHeaderCellBasenoStyle = ({ classes, ...restProps }) => {
  restProps.value = restProps.column.title || restProps.column.name;
  return <TableHeaderRow.Cell className={classes.customHeaderCell} {...restProps}/>
}
export const CustomTableHeaderCellnoStyle = withStyles(styles)(CustomTableHeaderCellBasenoStyle);

class MainListComponent extends Component {
  constructor(props) {
    super(props);
  };

  tableCell = props => {
    const { row, column } = props;
    let { valueCustomator, customClass, name, onCellClicked } = column;

    if (valueCustomator) {
      return (
        <Table.Cell
          onClick={onCellClicked ? () => onCellClicked(row) : null}
          className={onCellClicked ? "cursor-pointer" : null}
        >
          <div className={customClass}>{row ? valueCustomator(row) : ""}</div>
        </Table.Cell>
      );
    } else if (customClass) {
      return (
        <Table.Cell
          onClick={onCellClicked ? () => onCellClicked(row) : null}
          className={onCellClicked ? "cursor-pointer" : null}
        >
          <div className={customClass}>{row ? row[name] : ""}</div>
        </Table.Cell>
      );
    }

    return (
      <Table.Cell
        onClick={onCellClicked ? () => onCellClicked(row) : null}
        className={onCellClicked ? "cursor-pointer" : null}
        {...props}
      />
    );
  };

  tableRow = ({ row, ...restProps }, onRowClicked) => {
    if (onRowClicked) {
      return (
        <Table.Row
          {...restProps}
          onClick={() => onRowClicked({ row })}
          className="cursor-point table-row"
        />
      );
    }

    return <Table.Row {...restProps} />;
  };

  render() {
    let {
      styles,
      rows,
      onLoading,
      columns,
      currentPage,
      onCurrentPageChange,
      pageSize,
      totalCount,
      filterCell,
      searchPanel,
      onRowClicked,
      isUseBackendPaging,
      isUseLocalPaging,
      noBackendPagingPageSizes,
      sorting,
      onSortingChange,
      isUseSelectionRow,
      selection,
      columnWidths,
      tableRowDetail,
      columnExtensions,
      remoteSorting,
      selectFunction,
      settleButton,
      header,
      isNotUseSelectAll,
      isDragnDrop,
      centerheader,
      mainListData,
      expandedRowIds,
      onExpandedRowIdsChange,
      tableRowDetailOnChange,
      defaultPageSize,
    } = this.props;

    // if (!columnWidths || columnWidths.length <= 0) {
    //   columnWidths = columns.map(column => {
    //     return {
    //       columnName: column.name,
    //       width: 160
    //     };
    //   });
    // }

    return (
      <Paper style={styles}>
        <BlockUi tag="div" blocking={onLoading}>
          {!!header && <div style={{ padding: 16 }}>{header}</div>}
            {isDragnDrop &&
              <div>
                This is Drag n Drop
              </div>
            }
            {
              !isDragnDrop && 
                <GridTable rows={rows} columns={columns}>
                  {searchPanel && <SearchState defaultValue="" />}
                  {isUseBackendPaging && (
                    <PagingState
                      currentPage={currentPage}
                      onCurrentPageChange={onCurrentPageChange}
                      pageSize={pageSize}
                    />
                  )}
                  {isUseBackendPaging && <CustomPaging totalCount={totalCount} />}
                  {!isUseBackendPaging && (
                    <PagingState defaultCurrentPage={0} defaultPageSize={defaultPageSize || 10} />
                  )}
                  {(!isUseBackendPaging || isUseLocalPaging ) && <IntegratedPaging />}
                  {sorting && (
                    <SortingState
                      sorting={sorting}
                      onSortingChange={onSortingChange}
                      columnExtensions={columnExtensions}
                    />
                  )}
                  {sorting && !remoteSorting && <IntegratedSorting />}
                  {tableRowDetail && <RowDetailState defaultExpandedRowIds={[]} />}
                  {tableRowDetailOnChange && <RowDetailState defaultExpandedRowIds={[]} expandedRowIds={expandedRowIds ? expandedRowIds : []} onExpandedRowIdsChange={onExpandedRowIdsChange}/>}
                  <Table
                    rowComponent={params => this.tableRow(params, onRowClicked)}
                    cellComponent={this.tableCell}
                  />
                  {filterCell && <FilteringState />}
                  {filterCell && <IntegratedFiltering />}
                  {filterCell && <TableFilterRow cellComponent={filterCell} />}
                  {columnWidths && <TableColumnResizing defaultColumnWidths={columnWidths} />}
                  {(sorting && !centerheader) && <TableHeaderRow showSortingControls cellComponent={CustomTableHeaderCell}/>}
                  {(sorting && centerheader) && <TableHeaderRow showSortingControls cellComponent={CustomTableHeaderCellCenter}/>}
                  {!sorting && <TableHeaderRow cellComponent={CustomTableHeaderCellnoStyle}/>}
                  {/*<Toolbar />*/}
                  {/*<SearchPanel />*/}
                  {tableRowDetail && <TableRowDetail contentComponent={tableRowDetail} />}
                  {tableRowDetailOnChange && <TableRowDetail contentComponent={tableRowDetailOnChange} />}
                  {isUseBackendPaging ? (
                    <PagingPanel containerComponent={PagingContainer}/>
                  ) : (
                      <PagingPanel
                        containerComponent={PagingContainer}
                        pageSizes={noBackendPagingPageSizes || [5, 10, 0]}
                      />
                    )}
                  {isUseSelectionRow && <SelectionState
                    selection={selection}
                    onSelectionChange={selectFunction || this.changeSelection}
                  />}
                  {isUseSelectionRow && <IntegratedSelection />}
                  {isUseSelectionRow && !isNotUseSelectAll ? <TableSelection showSelectAll /> : isUseSelectionRow && <TableSelection />}
              </GridTable>
            }
        </BlockUi>
      </Paper>
    );
  }
}

export default MainListComponent;

export const PagingContainer = props => (
  <PagingPanel.Container {...props} className="custom-paging" />
);