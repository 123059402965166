import React, { Component } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

class LoadingCircular extends Component {
  render() {
    return (
      <div className="loading-container">
        <CircularProgress className="loading-circular" />
      </div>
    );
  }
}

export default LoadingCircular;