import React from "react";
import ReactQuill from "react-quill";
import { Card, CardBody, Form, FormInput, Button, Col, Row, Alert } from "shards-react";

import "react-quill/dist/quill.snow.css";
import "../../assets/quill.css";

import { createTask, checkToken } from "../../services/taskServices";
import LoadingCircular from "../LoadingCircular";


class TaskEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        title: "",
        dateFinish: null,
        listTask: [],
        task: "",
        loading: false
    }
  }

  componentDidMount = async () => {
      await checkToken();
  }

  onChange = (e) => {
      this.setState({
          [e.target.name]: e.target.value
      })
  }

  addValue = () =>{
      let tasks = this.state.listTask
      let task = {
        task: this.state.task,
        taskId: `#${Date.now()}`
      }
      tasks.push(task)
      this.setState({
          listTask: tasks,
          task: ""
      })
  }

  deleteValue = (index) =>{
    let tasks = this.state.listTask
    tasks.splice(index, 1)
    this.setState({
        listTask: tasks
    })
  }

  dateChange = (e) => {
      this.setState({
          dateFinish: e.target.value
      })
  }

  createTask = async () => {
      let body = {
        title: this.state.title,
        dateFinish: this.state.dateFinish,
        listTask: this.state.listTask
      }
      this.setState({
        loading: true
      }, async () => {
        await createTask(body)
        this.setState({
          title: "",
          dateFinish: null,
          listTask: [],
          task: "",
          loading: false
        }, () => {
          alert("Success Create New Task")
        })
      })
  }

  render() {
    const { placeholder } = this.props;
    return (
      <Card small className="mb-3">
        <CardBody>
          <Form className="add-new-post">
            <FormInput size="lg" className="mb-3" name="title" disabled={this.state.loading} value={this.state.title} onChange={(e) => this.onChange(e)} placeholder={placeholder} />
            <Col lg="3" md="12">
              <Row>
                <span className="page-subtitle">Finish Date</span>
              </Row>
              <Row>
                <FormInput type="date" disabled={this.state.loading} onChange={(e) => this.dateChange(e)} selected={this.state.dateFinish} className="mb-3"/>
              </Row>
            </Col>
            <Col lg="12" md="12">
                <Row>
                    <span className="page-subtitle">Tasks List</span>
                </Row>
            </Col>
            <Row>
                <Col lg="3" md="8" xs="9">
                    <FormInput className="mb-3" name="task" disabled={this.state.loading} placeholder={placeholder} value={this.state.task} onChange={(e) => this.onChange(e)} />
                </Col>
                <Col lg="3" md="8" xs="3">
                    <Button disabled={this.state.loading} onClick={(e) => this.addValue()}>+</Button>
                </Col>
            </Row>
            {
                this.state.listTask.map( (value, index) => {
                    return(
                        <Row key={index}>
                            <Col lg="12" md="12" xs="12">
                                <span className="page-subtitle">{value.taskId}</span>
                            </Col>
                            <Col lg="3" md="8" xs="9">
                                <FormInput disabled={true} className="mb-3" value={`${value.task}`} />
                            </Col>
                            <Col lg="3" md="8" xs="3">
                                <Button theme="danger" disabled={this.state.loading} onClick={(e) => this.deleteValue(index)}>-</Button>
                            </Col>
                        </Row>
                    )
                })
            }
            {/* <ReactQuill className="add-new-post__editor mb-3" /> */}
          </Form>
          <Button className="mb-2" onClick={(e) => this.createTask()}>Create Task</Button>
          {
            this.state.loading && 
            <LoadingCircular/>
          }
        </CardBody>
      </Card>
    )
  }
}

export default TaskEditor;
