import React from "react";
import { Redirect, Route } from "react-router-dom";
// import Loadable from "react-loadable";
// import LoadingCircular from "./components/LoadingCircular";
// Layout Types
import { DefaultLayout } from "./layouts";
// Routes
import ListTask from "./views/Task/ListTask";
import AddNewTask from "./views/Task/AddNewTask";

// function Loading() {
//   return <LoadingCircular />;
// }

// Route Views
// const ListTask = Loadable({
//   loader: () => import("./views/Task/ListTask"),
//   loading:  Loading,
// })
// const AddNewTask = Loadable({
//   loader: () => import("./views/Task/AddNewTask"),
//   loading: Loading
// })

export default [
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    component: () => <Redirect to="/task/add-new-task" />
  },
  // {
  //   path: "/blog-overview",
  //   layout: DefaultLayout,
  //   component: BlogOverview
  // },
  // {
  //   path: "/user-profile-lite",
  //   layout: DefaultLayout,
  //   component: UserProfileLite
  // },
  // {
  //   path: "/add-new-post",
  //   layout: DefaultLayout,
  //   component: AddNewPost
  // },
  {
    path: "/task",
    exact: true,
    layout: DefaultLayout,
    component: ListTask
  },
  {
    path: "/task/add-new-task",
    exact: true,
    layout: DefaultLayout,
    component: AddNewTask
  },
  // {
  //   path: "/errors",
  //   layout: DefaultLayout,
  //   component: Errors
  // },
  // {
  //   path: "/components-overview",
  //   layout: DefaultLayout,
  //   component: ComponentsOverview
  // },
  // {
  //   path: "/tables",
  //   layout: DefaultLayout,
  //   component: Tables
  // },
  // {
  //   path: "/blog-posts",
  //   layout: DefaultLayout,
  //   component: BlogPosts
  // }
];
