import React, { Component } from "react";
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  InputGroup,
  FormInput,
  Row
} from "shards-react";
import { loginWithEmailAndPassword } from "../../services/authService";
import { reactLocalStorage } from "reactjs-localstorage";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: ""
    };
    this.handleChange = this.handleChange.bind(this);
    this.login = this.login.bind(this);
  }

  componentDidMount = async () => {
    // TODO: Check LocalStorage.user exists or not
    // if (this.props.authStore.isAuthenticated) {
    //   window.location.href = "/dashboard";
    // } else {
    //   reactLocalStorage.clear();
    // //   deleteCookiesByName("ideal_current_token");
    // }
  };

  signInWithEmailAndPassword = async ({ username, password }) => {
    reactLocalStorage.clear();
    // deleteCookiesByName("ideal_current_token");
    this.currentUser = null;
    this.currentToken = null;
    this.authLoading = true;
    let token = "";
    let { data, error } = await loginWithEmailAndPassword({
      username,
      password,
      token
    });
    // this.authLoading = false;
    if (data) {
      this.currentUser = data.admin;
      this.currentToken = data.token;
      console.log(data)
      reactLocalStorage.setObject("idealCurrentUser", data.admin);
      reactLocalStorage.setObject("newDashboard", data.admin.newDashboard)
      reactLocalStorage.setObject("user_login", data.token)
      // setCookie("ideal_current_token", data.token, 86400000);
      // oneSignalStore.getSubscribe(history);
      // this.authLoading = true;
      // await this.getAdminPermission();
      this.authLoading = false;
      window.location.href = "/task/add-new-task";
    } else {
      this.authLoading = false;
    //   commonStore.showErrorMessage(error.response.data.message);
    }
  };

  handleChange = event => {
    const name = event.target.name;
    this.setState({ [name]: event.target.value });
  };

  login = event => {
    const { username, password } = this.state;
    this.signInWithEmailAndPassword({ username, password });
  };

  handleKeyPress = event => {
    if (event.key === "Enter") {
      this.login();
    }
  };

  render() {
    return (
      <div className="align-items-center" style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%'
      }}>
        <Container>
          <Row>
            <Col md="12">
              <div className="logo-login"></div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md="8">
              <CardGroup>
                <Card className="p-0">
                  <CardBody>
                    <Form className="style-label">
                      <h1 className="text-center mb-4">Login</h1>
                      {/* <p className="text-muted">Sign In to your account</p> */}
                      <span htmlFor="username">Username</span>
                      <InputGroup className="mb-3">
                        {/* <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-user" />
                          </InputGroupText>
                        </InputGroupAddon> */}
                        <FormInput
                          onKeyPress={this.handleKeyPress}
                          type="text"
                          name="username"
                          value={this.state.username}
                          placeholder="Username"
                          autoComplete="username"
                          onChange={this.handleChange}
                        />
                      </InputGroup>
                      <span htmlFor="password">Password</span>
                      <InputGroup className="mb-4">
                        {/* <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-lock" />
                          </InputGroupText>
                        </InputGroupAddon> */}
                        <FormInput
                          onKeyPress={this.handleKeyPress}
                          type="password"
                          name="password"
                          value={this.state.password}
                          placeholder="Password"
                          autoComplete="current-password"
                          onChange={this.handleChange}
                        />
                      </InputGroup>
                      <Row>
                        <Col xs="12" className="text-center">
                          {/* {authLoading && <LoadingCircular />} */}
                          {/* {!authLoading && (
                            
                          )} */}
                          <Button
                              color="primary"
                              onClick={this.login}
                              className="px-4 btn-green"
                            >
                              Login
                        </Button>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
                {/* <Card className="text-white bg-primary py-5 d-md-down-none" style={{ width: 44 + '%' }}>
                  <CardBody className="text-center">
                    <div>
                      <h2>Sign up</h2>
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua.</p>
                      <Button color="primary" className="mt-3" active>Register Now!</Button>
                    </div>
                  </CardBody>
                </Card> */}
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Login;