import axios from "./Axios.js";

let adminUrl = "https://api.i-dealhomeonline.com/admin"

export async function loginWithEmailAndPassword({ username, password }) {
  try {
    let loginResponse = await axios.Post({
      url: `${adminUrl}/auth/login`,
      data: { username, password }
    });

    if (loginResponse.statusCode !== 200) {
      return { error: loginResponse.message };
    }

    return { data: loginResponse.data };
  } catch (e) {
    return { error: e };
  }
}