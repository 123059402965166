import React from "react";
import ReactQuill from "react-quill";
import { Card, CardBody, Form, FormInput, Button, Col, Row, Container } from "shards-react";

import "react-quill/dist/quill.snow.css";
import "../../assets/quill.css";

import moment from 'moment';

import { getTaskList } from "../../services/taskServices";
import MainListComponent from "../../components/MainListComponent";

import TaskDetail from "./TaskDetail"


class ListTask extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        listMainTask: []
    }
  }

  componentDidMount = async () => {
      try {
        let { data, error } = await getTaskList();
        if (error) {
            throw error
        }
        console.log(data)
        this.setState({
            listMainTask: data
        })
      } catch (error) {

      }
  }

  render() {
    const valueCustomator = {
        createdDate: data => {
            return moment(
            data.createdDate && data.createdDate ? data.createdDate : ''
            ).format('YYYY/MM/DD H:mm:ss')
        },
        dateFinish: data => {
            return moment(
            data.dateFinish && data.dateFinish ? data.dateFinish : ''
            ).format('YYYY/MM/DD')
        },
        title: data => {
            return data.title ? data.title : '-'
        },
        admin: data => {
            return data.admin ? data.admin.name ? data.admin.name : "-" : "-"
        },
        tasksCount: data => {
            return data.task ? data.task.length : 0
        },
        completionCount: data => {
            let finish = 0
            if (data.task) {
                for (let i = 0; i < data.task.length; i ++) {
                    let task = data.task[i];
                    if (task.status === "finish") {
                        finish += 1
                    }
                }
            }
            return finish;
        }
    } 
    const columns = [
        {
            name: 'createdDate',
            title: 'Created Date',
            valueCustomator: valueCustomator.createdDate
        },
        {
            name: 'title',
            title: 'Task Title',
            valueCustomator: valueCustomator.title
        },
        {
            name: 'admin',
            title: 'Create By',
            valueCustomator: valueCustomator.admin
        },
        {
            name: 'listTask',
            title: 'Tasks',
            valueCustomator: valueCustomator.tasksCount
        },
        {
            name: 'listTask',
            title: 'Completion',
            valueCustomator: valueCustomator.completionCount
        },
        {
            name: 'dateFinish',
            title: 'Deadline',
            valueCustomator: valueCustomator.dateFinish
        },
    ];
    const columnWidths = [
        { columnName: 'createdDate', width: 180 },
        { columnName: 'title', width: 300 },
        { columnName: 'admin', width: 200 },
        { columnName: 'listTask', width: 100 },
        { columnName: 'dateFinish', width: 80 },
    ];
    return (
        <Container fluid className="main-content-container px-4 pb-4">
            <Col className="mt-4">
                <Row className="mb-2">
                    <Button
                        onClick={(e) => {
                            this.props.history.push('/task/add-new-task');
                        }}
                    >Add New Task</Button>
                </Row>
                <Row>
                    <MainListComponent
                        rows={this.state.listMainTask}
                        columns={columns}
                        columnWidths={columnWidths}
                        tableRowDetail={TaskDetail}
                        // currentPage={currentPage}
                        // pageSize={pageSize}
                        // totalCount={totalCount}
                        // isUseBackendPaging={isUseBackendPaging}
                        // noBackendPagingPageSizes={noBackendPagingPageSizes}
                        // onCurrentPageChange={(e) => onCurrentPageChange(e)}
                        // onLoading={onLoading}
                        // sorting={sorting}
                        // columnWidths={columnWidths}
                        // onSortingChange={onSortingChange}
                    />
                </Row>
            </Col>
        </Container>
    )
  }
}

export default ListTask;