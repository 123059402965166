import axios from "./Axios.js";
// import { apiUrl, adminUrl } from "../config/config.js";
// import { toJS } from "mobx";

// let adminUrl = "http://localhost:5000/admin"
let adminUrl = "https://idedashboard.i-dealhomeonline.com/admin"

export async function checkToken() {
  try {
    let loginResponse = await axios.Get({
      url: `${adminUrl}/token`
    });

    if (loginResponse.statusCode !== 200) {
      return { error: loginResponse.message };
    }
    
    return { data: loginResponse.data };
  } catch (e) {
    return { error: e };
  }
}

export async function getTaskList() {
    try {
      let response = await axios.Get({
        url: `${adminUrl}/tasks`
      });
      if (response.statusCode !== 200) {
        return { error: response.message };
      }
  
      return { data: response.data };
    } catch (e) {
      return { error: e.response.data };
    }
}

export async function getTask(taskId) {
    try {
      let response = await axios.Get({
        url: `${adminUrl}/tasks/${taskId}`
      });
      if (response.statusCode !== 200) {
        return { error: response.message };
      }
  
      return { data: response.data };
    } catch (e) {
      return { error: e.response.data };
    }
}

export async function createTask(body) {
    try {
      let response = await axios.Post({
        url: `${adminUrl}/tasks/create`,
        data: { taskModel: body }
      });
      if (response.statusCode !== 200) {
        return { error: response.message };
      }
  
      return { data: response.data };
    } catch (e) {
      return { error: e.response.data };
    }
}